import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-topeka-kansas.png'
import image0 from "../../images/cities/scale-model-of-world's-largest-wren-statue-in-topeka-kansas.png"
import image1 from "../../images/cities/scale-model-of-great-overland-station-in-topeka-kansas.png"
import image2 from "../../images/cities/scale-model-of-evel-knievel-museum-in-topeka-kansas.png"
import image3 from "../../images/cities/scale-model-of-old-prairie-town-at-ward-meade-historic-site-and-botanical-garden-in-topeka-kansas.png"
import image4 from "../../images/cities/scale-model-of-topeka-zoo-in-topeka-kansas.png"
import image5 from "../../images/cities/scale-model-of-ward-meade-park-in-topeka-kansas.png"
import image6 from "../../images/cities/scale-model-of-kansas-tourism---kansas-dept.-of-wildlife,-parks,-&-tourism-in-topeka-kansas.png"
import image7 from "../../images/cities/scale-model-of-visit-topeka-in-topeka-kansas.png"
import image8 from "../../images/cities/scale-model-of-cowabunga-safaris-in-topeka-kansas.png"
import image9 from "../../images/cities/scale-model-of-dillon-house-events-in-topeka-kansas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Topeka'
            state='Kansas'
            image={image}
            lat='39.0473451'
            lon='-95.67515759999998'
            attractions={ [{"name": "World's Largest Wren Statue", "vicinity": "466-498 SW Huntoon St, Topeka", "types": ["point_of_interest", "establishment"], "lat": 39.04430199999999, "lng": -95.68241469999998}, {"name": "Great Overland Station", "vicinity": "701 N Kansas Ave, Topeka", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.0662718, "lng": -95.66944509999996}, {"name": "Evel Knievel Museum", "vicinity": "2047 SW Topeka Blvd, Topeka", "types": ["museum", "point_of_interest", "establishment"], "lat": 39.0301537, "lng": -95.68356059999996}, {"name": "Old Prairie Town at Ward-Meade Historic Site and Botanical Garden", "vicinity": "124 NW Fillmore St, Topeka", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 39.063511, "lng": -95.68328600000001}, {"name": "Topeka Zoo", "vicinity": "635 SW Gage Blvd, Topeka", "types": ["zoo", "point_of_interest", "establishment"], "lat": 39.055872, "lng": -95.726902}, {"name": "Ward-Meade Park", "vicinity": "124 NW Fillmore St, Topeka", "types": ["park", "point_of_interest", "establishment"], "lat": 39.0631571, "lng": -95.68216860000001}, {"name": "Kansas Tourism - Kansas Dept. of Wildlife, Parks, & Tourism", "vicinity": "1020 S Kansas Ave Suite #200, Topeka", "types": ["travel_agency", "point_of_interest", "local_government_office", "establishment"], "lat": 39.04493000000001, "lng": -95.67569500000002}, {"name": "Visit Topeka", "vicinity": "719 S Kansas Ave Suite 100, Topeka", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 39.0496239, "lng": -95.67459759999997}, {"name": "Cowabunga Safaris", "vicinity": "2108 SW Fairlawn Plaza Dr, Topeka", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 39.0285166, "lng": -95.74590439999997}, {"name": "Dillon House Events", "vicinity": "404 SW 9th St, Topeka", "types": ["point_of_interest", "establishment"], "lat": 39.048894, "lng": -95.67978599999998}] }
            attractionImages={ {"World's Largest Wren Statue":image0,"Great Overland Station":image1,"Evel Knievel Museum":image2,"Old Prairie Town at Ward-Meade Historic Site and Botanical Garden":image3,"Topeka Zoo":image4,"Ward-Meade Park":image5,"Kansas Tourism - Kansas Dept. of Wildlife, Parks, & Tourism":image6,"Visit Topeka":image7,"Cowabunga Safaris":image8,"Dillon House Events":image9,} }
       />);
  }
}